import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';

[...document.querySelectorAll('.gallery-slider-block')].forEach(container => {
    const slider = container.querySelector('.swiper');
    if (slider.swiper) {
        slider.swiper.destroy();
    }
    const config = {
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            640: {
                slidesPerView: parseInt(slider.dataset.swiperSlides) || 1,
                spaceBetween: 32
            }
        },
        loop: true,
        modules: [Autoplay]
    };
    if (slider.dataset.swiperNavigation?.toString() === 'true') {
        config['navigation'] = {
            nextEl: container.querySelector('.swiper-button-next'),
            prevEl: container.querySelector('.swiper-button-prev'),
        };
        config.modules.push(Navigation);
        container.classList.remove('no-navigation');
    } else {
        container.classList.add('no-navigation');
    }
    if (slider.dataset.swiperPagination?.toString() === 'true') {
        config['pagination'] = {
            el: container.querySelector('.swiper-pagination'),
            clickable: true,
        };
        config.modules.push(Pagination);
        container.classList.remove('no-pagination');
    } else {
        container.classList.add('no-pagination');
    }
    console.error(config)
    new Swiper(slider, config);
})
