const fallbackCopyTextToClipboard = text  => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    let successful = false;

    try {
        document.execCommand('copy');
        successful = true;
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
    return successful;
}

const copyTextToClipboard = text => new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
        if (fallbackCopyTextToClipboard(text)) {
            resolve();
        }
    }
    navigator.clipboard.writeText(text).then(function () {
        resolve();
    }, function (err) {
        reject(err);
    });
});

[...document.querySelectorAll('.content-builder [data-source="code"]')].forEach(item => {
    const btn = document.querySelector('button');
    btn.onclick = async() => {
        let className = 'not-copied';
        try {
            await copyTextToClipboard(item.querySelector('code').innerText);
            className = 'copied';
        } catch (err) {
        }
        btn.classList.add(className);
        setTimeout(() => btn.classList.remove(className), 2000);
    }
})
