const navigation = document.getElementById('navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.getElementById('navigation-dropdown');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
	if (!item.classList.contains('dropdown')) {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	}
});

const toggleDropdown = () => {
	if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
		navigationToggler?.click();
	}
};

// header primary

$('.header-primary__hamburger .hamburger').click(function(e){

    e.preventDefault();
    e.stopPropagation();

    $(this).toggleClass('hamburger--active');
    $('.header-primary').toggleClass('header-primary--nav-is-open');
    $('.b-overlay').toggleClass('b-overlay--active');

    $('html').toggleClass('disable-scroll');

});

$('.header-primary__nav').click(function(e){
    e.stopPropagation();
});

$('body').click(function(){

    $('.header-primary__hamburger .hamburger').removeClass('hamburger--active');
    $('.header-primary').removeClass('header-primary--nav-is-open');
    $('.b-overlay').removeClass('b-overlay--active');

    $('html').removeClass('disable-scroll');

});

$(window).resize(function(){

    let widthWindow = $(this).outerWidth();

    if(widthWindow > 767 && $('.header-primary').hasClass('header-primary--nav-is-open')) {
        $('.header-primary__hamburger .hamburger').removeClass('hamburger--active');
        $('.header-primary').removeClass('header-primary--nav-is-open');
        $('html').removeClass('disable-scroll');
    }

});


$(window).scroll(function(){

    let scrollTop = $(this).scrollTop();

    if(scrollTop > 0) {
        $('.header-primary').addClass('header-primary--is-scroll');
    } else {
        $('.header-primary').removeClass('header-primary--is-scroll');
    }

});

// scroll to

$('a[href*="#"]').not('[href="#"]').click(function(e) {

	e.preventDefault();

    let href = $(this).attr('href'),
        sectionName = href.split('#')[1],
        section = $('#' + sectionName),
        sectionOffsetTop = section.offset().top - $('.header-primary').outerHeight();

	$('html, body').animate({
		scrollTop: sectionOffsetTop
	}, 300);

	return false;

});