import * as basicLightbox from 'basiclightbox';

[...document.querySelectorAll('.gallery-item')].forEach(item => {
    item.addEventListener('click', (event) => {
        event.preventDefault();
        const lightbox = basicLightbox.create(`
        <div class="lightbox-modal-backdrop">
            <div class="lightbox-modal">
                ${item.innerHTML}
            </div>
        </div>
        `);
        lightbox.show();
    });
});
